import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import gsap from 'gsap';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
import Icon from 'src/components/Icon';
// hooks
import useWindowSize from 'src/hooks/useWindowSize';
// context
import SitePreloaderContext from 'src/contexts/SitePreloaderContext';
import BannerContext from 'src/contexts/BannerContext';
// animation
import { appearanceOfText } from 'src/animations/text';
import { drawSvg } from 'src/animations/svg';
import { showBlock } from 'src/animations/block';

import type { Props } from './Intro.interface';
import * as style from './Intro.module.scss';

const Intro: FC<Props> = ({
  title,
  mobileTitle,
  description,
  icon,
  withMargin = true,
}) => {
  // context
  const { isPageLoaded } = useContext(SitePreloaderContext);
  const { isTopBannerVisible, topBannerRef } = useContext(BannerContext);
  // hooks
  const { isTablet, isMobile } = useWindowSize();
  // ref
  const introBlockRef = useRef<HTMLDivElement>(null);
  const drawingImageRef = useRef<HTMLDivElement>(null);
  const blockAnimationRef = useRef<gsap.core.Tween | null>(null);
  const imageAnimationRef = useRef<gsap.core.Tween | null>(null);
  // memo
  const titleText = useMemo(() => {
    return (isMobile || isTablet) && mobileTitle ? mobileTitle : title;
  }, [mobileTitle, title, isTablet, isMobile]);

  useEffect(() => {
    if (introBlockRef.current !== null) {
      blockAnimationRef.current = showBlock(introBlockRef.current).pause();
    }

    if (drawingImageRef.current !== null) {
      imageAnimationRef.current = drawSvg(drawingImageRef.current).pause();
    }
  }, []);

  useEffect(() => {
    if (
      introBlockRef.current !== null &&
      drawingImageRef.current !== null &&
      isPageLoaded
    ) {
      imageAnimationRef.current?.play();
      blockAnimationRef.current?.play();
      appearanceOfText(`.${style.animatedText}`).play();
    }
  }, [isPageLoaded]);

  return (
    <Section className={style.root} withMargin={withMargin}>
      <div
        className={style.introBlock}
        ref={introBlockRef}
        style={{
          marginTop:
            !isTablet && !isMobile && isTopBannerVisible
              ? topBannerRef.current?.clientHeight
              : 0,
        }}
      >
        <Section
          className={style.heading}
          variant='pad-full'
          withMargin={false}
          padMobileRight
        >
          <Typography
            className={classNames(style.title, style.animatedText)}
            variant='h1'
          >
            {titleText}
          </Typography>
          <Typography
            className={classNames(style.description, style.animatedText)}
            variant='body'
          >
            {description}
          </Typography>
        </Section>
      </div>
      <div
        className={style.iconWrapper}
        ref={drawingImageRef}
        style={{
          marginTop:
            (isTablet || isMobile) && isTopBannerVisible
              ? topBannerRef.current?.clientHeight
              : 0,
        }}
      >
        <Icon name={icon} />
      </div>
    </Section>
  );
};

export default Intro;
