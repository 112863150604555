import { FC } from 'react';
// components
import Typography from 'src/components/Typography';
import TextContent from 'src/components/TextContent';
import Link from 'src/components/Link';

import * as style from 'src/components/TextContent/TextContent.module.scss';

const Content: FC = () => {
  return (
    <TextContent>
      <Typography variant='h3' className={style.title}>
        General
      </Typography>
      <Typography variant='body'>
        Our{' '}
        <Link href='/privacy-policy' variant='internal'>
          Privacy Policy
        </Link>{' '}
        explains what information of yours will be collected by Seedium when you
        access our website at{' '}
        <Link href='https://seedium.io' target='_blank' rel='noreferrer'>
          seedium.io
        </Link>{' '}
        (the “Website”) or communicate with us through email, phone, or other
        means. It also provides our principles when it comes to using cookies.
      </Typography>
      <Typography variant='body'>
        This Cookie Policy specifically explains how we deploy cookies, as well
        as the options you have to control them.
      </Typography>

      <Typography variant='h3' className={style.title}>
        What are cookies?
      </Typography>
      <Typography variant='body'>
        A cookie is a small text file that might be stored on your computer or
        mobile device when you visit the Website. It can help a Website to
        recognize you on your next visit ensuring a consistent and efficient
        user experience and the performance of essential functions.
      </Typography>
      <Typography variant='body'>
        The Website uses different types of cookies for different reasons. In
        particular, the cookies may be used to remember you and your
        preferences, either for a single visit{' '}
        <strong>(session cookies)</strong> or for multiple repeat visits{' '}
        <strong>(persistent cookies)</strong>.
      </Typography>
      <Typography variant='body'>
        The cookies that are set by the Website are{' '}
        <strong>first-party cookies</strong> and only we can read them. However,
        the Website might potentially use external services, which also set
        their own cookies, known as <strong>third-party cookies</strong>.
      </Typography>
      <Typography variant='body'>
        We use the <strong>first-party cookies</strong> to store visitor
        preferences, make our Website operational and gather analytics data.
        Some of the cookies might also be necessary for technical reasons for
        certain pages of the Website to function.
      </Typography>
      <Typography variant='body'>
        For internal research on how we can improve the Website or the services
        provided, we use <strong>analytics cookies</strong>. They help us to
        assess how you interact with the Website as an anonymous user and the
        <strong>data gathered does not identify you personally</strong>.
      </Typography>
      <Typography variant='body'>
        Since some of the abovementioned cookies are essential for our Website
        and services to perform basic functions and are necessary for us to
        operate certain features, they are considered{' '}
        <strong>essential cookies</strong>.
      </Typography>

      <Typography variant='h3' className={style.title}>
        How can you manage cookies?
      </Typography>
      <Typography variant='body'>
        Every time you visit the Websites, you will be prompted to{' '}
        <strong>accept or refuse cookies</strong>.
      </Typography>
      <Typography variant='body'>
        By accepting our{' '}
        <Link href='/privacy-policy' variant='internal'>
          Privacy Policy
        </Link>{' '}
        and remaining on the Website, you agree with our Website’s use of
        cookies.
      </Typography>
      <Typography variant='body'>
        You can set your browser to refuse all or some browser cookies or to
        alert you when internet sites set or access cookies. If you disable or
        refuse cookies some parts of the Website may become inaccessible or not
        function properly.
      </Typography>
      <Typography variant='body'>
        You can also delete all cookies that are already on your device by
        clearing the browsing history of your browser. This will remove all
        cookies from all websites you have visited. Be aware though that you may
        also lose some saved information (e.g. saved login details, site
        preferences, etc.).
      </Typography>
      <Typography variant='body'>
        If you have any questions about our use of cookies, you can find the
        most relevant contact details in our{' '}
        <Link href='/privacy-policy' variant='internal'>
          Privacy Policy
        </Link>
        .
      </Typography>
    </TextContent>
  );
};

export default Content;
