import gsap from 'gsap';
// animation
import { showFromOpacity } from 'src/animations/block';

type DrawingAnimationOptions = {
  delay?: number;
  duration?: number;
};

const defaultOptions: DrawingAnimationOptions = {
  delay: 0,
  duration: 3,
};

function drawSvg(node: HTMLElement, options?: DrawingAnimationOptions) {
  const _options = { ...defaultOptions, ...options };
  const paths = node?.querySelectorAll('path');

  if (paths) {
    paths.forEach((path) => {
      const length = path.getTotalLength();

      path.style.setProperty('stroke-dasharray', `${length}`);
      path.style.setProperty('stroke-dashoffset', `${length}`);
    });

    return gsap.to(paths, {
      strokeDashoffset: 0,
      delay: _options.delay,
      duration: _options.duration,
    });
  }

  return showFromOpacity(node);
}

export { drawSvg };
